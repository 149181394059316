<template>
  <div class="knowledge-search-page">
    <van-search
      v-model="couponParams.coupon_name"
      show-action
      placeholder="搜索"
      @search="onSearch"
      @cancel="onCancel"
    />
    <div class="search-content">
      <van-list
        v-if="couponData.length"
        v-model:loading="form.loading"
        v-model:error="form.error"
        :finished="form.finished"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
        :finished-text="couponData.length == 0 ? '暂无数据' : '没有更多了'"
        @load="onload"
      >
        <div
          class="p15"
          v-for="item in couponData"
          :key="item.coupon_id"
          @click="openInstuctions(item)"
        >
          <div class="coupon-class">
            <div class="circular"></div>
            <div class="flex flex-align-center">
              <div
                class="coupon-price flex flex-direction-column flex-justify-center"
              >
                <div class="tc">
                  <span class="fs12 clff fw400">￥</span>
                  <span class="fs36 clff fw700">
                    {{ Number(item.sub_money) }}
                  </span>
                </div>
                <div class="fs12 tc clff fw400" v-if="item.use_type === 0">
                  无门槛
                </div>
                <div class="fs12 tc clff fw400" v-if="item.use_type === 1">
                  满 {{ Number(item.full_money) }} 元可用
                </div>
              </div>
              <div class="coupon-message p12">
                <div class="fs14 fw400 cl00 txt-line-1">
                  {{ item.coupon_name }}
                </div>
                <div class="mt6 cl99 fs12 cl99">{{ item.receive_time }}</div>
                <div class="flex flex-align-center flex-justify-between mt5">
                  <van-button
                    round
                    type="primary"
                    size="mini"
                    :disabled="item.sub_total == 0"
                    @click.stop="handleShare(item, index)"
                  >
                    发送
                  </van-button>
                  <van-icon name="arrow-down" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="item.showMore"
            class="coupon-instructions-box p12 cl00 fw400 fs12"
          >
            <div>
              <div>使用说明：</div>
              <div class="mt12">
                {{ item.coupon_desc || '--' }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <div
        v-if="couponData.length === 0 && form.searched"
        class="search-nodata"
      >
        暂无搜索结果
      </div>
    </div>
  </div>
</template>

<script setup>
import { Toast } from 'vant'
import { reactive, ref } from 'vue'
import { getCouponList, couponShareImg } from '../../../service/checkQr.service'
import { useRouter, useRoute } from 'vue-router'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { formatParamsDistribution } from '@/utils/index.js'
import { sendMonitor } from '@/service/quickSend.service.js'
const router = useRouter()
const route = useRoute()
const couponData = ref([])
const couponId = ref(null)
const instructions = ref(false)
const couponParams = reactive({
  page: 1,
  page_size: 10,
  coupon_name: ''
})
const form = reactive({
  loading: false,
  error: false,
  finished: false,
  searched: false,
  count: 0
})

const share_data_id = ref('')

// 搜索
const onSearch = () => {
  couponData.value = []
  couponParams.page = 1

  if (!couponParams.coupon_name) {
    return
  }
  form.searched = true
  onload()
}

// 列表请求
const onload = async () => {
  if (couponData.value.length > 0) {
    couponParams.page += 1
  }
  try {
    form.loading = true
    const { data, code } = await getCouponList(couponParams)

    if (code !== 200) {
      form.error = true
      form.count = 0
      throw code
    }

    form.count = data.total_num || 0
    couponData.value = couponData.value.concat(data.list)
    form.loading = false

    // 翻到最后一页
    if (
      couponData.value.length < couponParams.page_size ||
      form.count === couponData.value.length
    ) {
      form.finished = true
    }
  } catch (error) {
    form.error = true
    form.finished = true
    console.log(error)
  } finally {
    form.loading = false
    return Promise.resolve(true)
  }
}

// 取消
const onCancel = () => {
  router.go(-1)
}
const openInstuctions = (obj) => {
  console.log('objobjobj', obj.showMore)
  if (obj.showMore) {
    obj.showMore = !obj.showMore
  } else {
    obj.showMore = true
  }
}

// 发送前的埋点回调
const beforeSend = async (coupon_id) => {
  let { data, code } = await sendMonitor({
    payable_id: coupon_id,
    type: 'goods',
    user_id: route.query.externalUserId || ''
  })
  if (code == 200) {
    share_data_id.value = data.share_data_id
  }
  return Promise.resolve(true)
}

const handleShare = async (item) => {
  Toast.loading({
    message: '加载中',
    forbidClick: true,
    duration: 0
  })
  if (route.query.externalUserId) {
    await beforeSend(item.coupon_id)
  }
  try {
    let params = {}
    params.coupon_id = item.coupon_id
    const { data } = await couponShareImg(params)
    let title = data.title
    let imgUrl = data.imgUrl
    console.log(
      'id是否获取成功',
      route.query.externalUserId,
      route.query.chatId
    )
    let page = `pages/coupon/receiveCoupon/index.html?coupon_id=${item.coupon_id}&member_id=${route.query.userId}&is_share=1&externalUserId=${route.query.externalUserId}&chatId=${route.query.chatId}`
    page = await formatParamsDistribution(
      page,
      route.query.externalUserId,
      route.query.chatId,
      'b2c_coupon'
    )

    // 如果存在埋点id加入埋点id
    if (share_data_id.value)
      page = `${page}&share_data_id=${share_data_id.value}`
    let shareObj = {
      appid: data.appid, //小程序的appid
      title,
      imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
      page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
    }
    // console.log('发送前参数', shareObj)
    await sendChatMessageAllType('miniprogram', {
      miniprogram: { ...shareObj }
    })
    Toast.clear()
  } catch (e) {
    console.log('报错', e)
    Toast.clear()
  }
}
// console.log('111111111', route.query.userId)
</script>

<style lang="less" scoped>
.knowledge-search-page {
  .search-content {
    box-sizing: border-box;
    // width: 295px;
    background-color: #f5f5f5;
    height: calc(100vh - 55px);
    padding: 20px 15px;
    overflow-y: scroll;
    position: relative;
  }

  .search-nodata {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    color: #909399;
    text-align: center;
    font-size: 14px;
  }
}
.coupon-class {
  position: relative;
  width: 100%;
  //   height: 100px;
  .coupon-price {
    width: 40%;
    // height: 100px;
    align-self: normal;
    background: #1773fa;
  }
  .coupon-message {
    width: 60%;
    // height: 100px;
    background: #ffffff;
  }
  .circular {
    position: absolute;
    left: 0px;
    top: 46%;
    width: 8px;
    height: 16px;
    background-color: white;
    border-radius: 0px 50px 50px 0px;
  }
}

.coupon-instructions-box {
  width: 100%;
  background: #ffffff;
}
:deep(.van-button--mini) {
  width: 56px !important;
  font-size: 14px !important;
}
</style>
